<template>
    <div>Loading...</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'redirectAuth',
  mounted () {
    this.verifyUser()
  },
  methods: {
    ...mapActions('auth', ['verifyAuthToken']),
    verifyUser () {
      this.verifyAuthToken({ token: this.$route.query.auth, slug: window.location.host.split('.')[0] })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
